import React from 'react';
import "../../../styles/ServicesHoverCards.scss"

function AggregatesHoverCards() {
    const hoverDefault = " "
    let [hover, setHover] = React.useState(hoverDefault);
    let t : any;
    return (
        <div className={"aggContainer"}>
            <div onMouseEnter={() => {t = setTimeout(()=>{ setHover("1")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175);}}
                 className={"hoverBox"}> {hover === "1" ? <div className={"blur"}>

                <p>
                    The 3 ways of sampling defined by AS1141.3.1 is used to ensure the accuracy and credibility
                    for soil and aggregate.
                </p>
            </div> : <div>  <h1>Sampling</h1>

            </div>} </div>
            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("2")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175);}}
                 className={"hoverBox"}> {hover === "2" ? <div className={"blur"}>
                <p>
                    The scope of this test is to determine the bulk density of fine, coarse, mixed aggregates. The bulk density is determined in the uncompacted and compacted states.
                </p>
                <p>
                    The test is normally performed on material dried to constant mass but may be performed at other moisture conditions if specifically required.
                    The values obtained for bulk density of fine aggregates at different matures conditions will differ significantly
                </p>
            </div> : <div> <h1> Bulk density </h1>

            </div>} </div>
            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("3")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={"hoverBox"}> {hover === "3" ? <div className={"blur"}>
                <p>
                    This test is carried out by following AS 1141.5 and AS 1141.6.1 Depending on the respective situation being fine or Couse material.
                </p>
                <p>
                    This test determines the particle density, the apparent particle density and the water absorption of fine aggregates or the fine fraction of an aggregate
                </p>
            </div> : <div> <h1> Particle density and water absorption </h1>

            </div>} </div>
            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("4")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={"hoverBox"}> {hover === "4" ? <div className={"blur"}>
                <p>
                    This test is conducted following AS 1141.11.1 and 1141.12 depending on the size of particle size
                </p>
                <p>
                    The aggregate is put through or washed through a nest of various sieve sizes relating to its required specification to insure the correct mechanical interlocking is achieved.
                </p>
            </div> : <div> <h1> Particle size distribution </h1>

            </div>} </div>

            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("5")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={"hoverBox"}> {hover === "5" ? <div className={"blur"}>
                <p> These tests </p>
                <p>
                    All insure the material tested is not to flaky and there is enough angularity in the aggregate to play its important role in
                    maximum mechanical interlocking.
                </p>
            </div> : <div> <h1> Proportional calliper, flakiness index, average least dimension, crushed faces and weak particles </h1>

            </div>} </div>

            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("6")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={"hoverBox"}> {hover === "6" ? <div className={"blur"}>
                <p>
                    These tests are performed to determine the strength of the most predominant aggregate size that is being used in the material
                    by the use of compression machine or the pulverising of the material.
                </p>
            </div> : <div> <h1> Aggregate crushing value, wet/dry strength variation and los angeles value </h1>

            </div>} </div>

            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("7")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={"hoverBox"}> {hover === "7" ? <div className={"blur"}>
                <p>
                    AS 1141.25.2 is followed to determine the degradation factor of coarse aggregates.
                </p>
                <p>
                    This involves the testing of the intermediate sizes of the material by finding out what fines will be generated by putting the raw material through a self-abrasion process.
                </p>
            </div> : <div> <h1> Degradation Factor </h1>

            </div>} </div>
            {/*
            <div onMouseEnter={() => {t = setTimeout(()=>{setHover("8")},175)}}
                 onMouseLeave={() => {setTimeout(()=>{setHover(hoverDefault)},175); clearTimeout(t);}}
                 className={"hoverBox"}> {hover === "8" ? <div className={"blur"}>
                <p>
                    bla bla bla ooga bla bla ooga bla bla bla ooga bla bla ooga bla bla bla ooga bla bla ooga bla bla bla
                    ooga bla bla ooga bla bla bla ooga bla bla ooga bla bla bla
                </p>
            </div> : <div> <h1> Filler Title </h1>

            </div>} </div> */}
        </div>
    );
}

export default AggregatesHoverCards;
